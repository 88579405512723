import _DEFAULTS from "@/utils/defaults/fqcn.json";
import { IFQCN_BUI } from "@/utils/types/common";

/**
 * @param bui Bundle unit interface object
 * @param Part part of the doc
 * @param Element element belonging to the part of the doc
 * @returns return the fqcn as a string in the format 'pfqnc_phprconfig_appBundle_main_menu_links'
 */

export const getFQCN = (
  bui: IFQCN_BUI,
  Part: string | null,
  Element?: string | null
): string => {
  let fqcn = `${_DEFAULTS.Indicator}_${_DEFAULTS.Application}_${bui.Bundle}_${
    bui.Unit
  }_${bui.Interface}${Part ? "_" + Part : ""}${Element ? "_" + Element : ""}`;
  return fqcn;
};
